export const breadcrumbItems = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
    isCarSelector: true,
    step: 1,
  },
  {
    key: 'model',
    label: 'langkey.breadcrumb-model',
    isCarSelector: true,
    step: 2,
  },
  {
    key: 'type',
    label: 'langkey.breadcrumb-type',
    isCarSelector: true,
    step: 3,
  },
  {
    key: 'version',
    label: 'langkey.breadcrumb-version',
    isCarSelector: true,
    step: 4,
  },
  {
    key: 'wheels',
    label: 'Wheels',
  },
  {
    key: 'tpms',
    label: 'TPMS',
  },
];

export const tableFields = [
  { value: 'manufacturer', text: 'langkey.brand', align: 'center', width: `${(2/15)*100}%` },
  { value: 'group', text: 'langkey.type', align: 'center',  width: `${(2/15)*100}%` },
  { value: 'image', text: 'langkey.image', align: 'center', sortable: false, width: `${(3/15)*100}%` },
  { value: 'description1', text: 'langkey.article', align: 'left', sortable: false, width: `${(4/15)*100}%` },
  { value: 'purchasePrice', text: 'langkey.best-price-rim-config', align: 'center', width: `${(2/15)*100}%` },
  { value: 'actions', text: '', align: 'center', sortable: false, width: '40px' },
];

export const tableFieldsRetailPrice = [
  { value: 'manufacturer', text: 'langkey.brand', align: 'center', width: `${(2/15)*100}%` },
  { value: 'group', text: 'langkey.type', align: 'center',  width: `${(2/15)*100}%` },
  { value: 'image', text: 'langkey.image', align: 'center', sortable: false, width: `${(3/15)*100}%` },
  { value: 'description1', text: 'langkey.article', align: 'left', sortable: false, width: `${(4/15)*100}%` },
  { value: 'retailPrice', text: 'langkey.netto', align: 'center', width: `${(2/15)*100}%` },
  { value: 'retailTaxPrice', text: 'langkey.brutto', align: 'center', width: `${(2/15)*100}%` },
  { value: 'actions', text: '', align: 'center', sortable: false, width: '40px' },
];

export const tableFieldExpand = [
  { value: 'image', text: 'langkey.image', align: 'center' },
  { value: 'article', text: 'langkey.article', align: 'center' },
  { value: 'actions', text: '', align: 'center' },
];

export const selectFilterFields = [
  {
    key: 'manufacturer',
    placeholder: 'langkey.tpms-prop.manufaturer',
    singleSelectLabel: 'Manufacturer',
    pluralSelectLabel: 'Manufacturers',
  },
];