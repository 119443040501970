<template>
  <div class="rims-config-tpms_carTag">
    <rimconfig-breadcrumb
      :breadcrumb-items="breadcrumbItems"
      @set-car-selector-step="setCarSelectorStep"
    />
    <base-rimconfig-filter-bar
      has-checkbox
      has-reset
      has-select
      @reset="onResetFilter"
    >
      <template #checkbox-group>
        <div class="configurator-car-checkbox-list">
          <base-checkbox
            v-for="(tpmsGroup, index) in tpmsGroupFilterCheckboxes"
            :key="`${index}`"
            v-model="inputGroupFilter[tpmsGroup]"
            :label-text="$t(tpmsGroup)"
            class="checkbox-item"
            @input="onSelectCheckboxFilter(inputGroupFilter[tpmsGroup], tpmsGroup)"
          />
        </div>
      </template>
      <template #select-group>
        <div class="configurator-car-dropdown-list">
          <base-select-filter-dropdown
            v-for="(selectField, index) in selectFilterFields"
            :key="`select${selectField.key}${index}`"
            v-model="inputSelectFilter[selectField.key]"
            :placeholder="$t(selectField.placeholder)"
            :select-label="inputSelectFilter[selectField.key] && inputSelectFilter[selectField.key].length > 1 ? selectField.pluralSelectLabel : selectField.singleSelectLabel"
            :option-items="selectFilterOptions[selectField.key] || []"
            @input="onSelectDropdownFilter($event, selectField.key)"
          />
        </div>
      </template>
    </base-rimconfig-filter-bar>
    <div class="tpms-table">
      <v-container>
        <v-data-table
          class="primary-style"
          :headers="tableFields"
          :items="filteredTpmsSearchData"
          :loading="isLoading"
          :single-expand="singleExpand"
          :expanded="expanded"
          item-key="tpmsTag"
          disable-pagination
          fix-header
          hide-default-footer
          @click:row="expandRow"
        >
          <template
            v-for="(field, index) in tableFields"
            v-slot:[`header.${field.value}`]="{ header }"
          >
            <div
              :key="`${field.value}${index}`"
              class="d-flex"
              :class="field.value !== 'description1' ? 'align-center justify-center' : null"
            >
              <span>{{ $t(header.text) }}</span>
              <div class="icon__sort" />
            </div>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <img
              v-if="item.imageTemplate"
              v-lazy="getTpmsImageBySize(item.imageTemplate)"
              class="tpms-img"
            >
          </template>
          <template v-slot:[`item.description1`]="{ item }">
            <p class="text-left">
              {{ item.description1 }} {{ item.description2 }}
            </p>
          </template>
          <template v-slot:[`item.purchasePrice`]="{ item }">
            {{ item.prices.bestPrice.purchasePrice | price }}
          </template>
          <template v-slot:[`item.retailPrice`]="{ item }">
            {{ item.prices.bestPrice.retailPrice | price }}
          </template>
          <template v-slot:[`item.retailTaxPrice`]="{ item }">
            {{ item.prices.bestPrice.retailTaxPrice | price }}
          </template>
          <template v-slot:[`item.actions`]="{ expand, isExpanded, item }">
            <v-btn
              v-if="item.children"
              depressed
              color="accent"
              @click="expand(!isExpanded)"
            >
              Valves
              <icon-close
                v-show="expanded.length && expanded[0].articleId === item.articleId"
                class="icon"
                size="14"
                color="var(--theme-white)"
              />
              <icon-caret-double
                v-show="!expanded.length || expanded[0].articleId !== item.articleId"
                class="icon"
                color="var(--theme-white)"
              />
            </v-btn>
            <v-btn
              v-else
              plain
              class="button__input-clear"
              @click="order(item)"
            >
              <icon-add-to-cart />
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </div>
    <base-add-to-cart-dialog
      ref="addToCartDialog"
      :cart-items="cartItems"
      :select-quantity-table="selectQuantity"
      supplier-source
      @on-subtract-quantity="onSubtractQuantity"
      @on-add-quantity="onAddQuantity"
      @on-update-quantity="onUpdateQuantity"
      @supplier-add-cart="onSupplierAddToCart"
    />
  </div>
</template>

<style lang="scss">
@import "@/styles/pages/rims-config-tpms_carTag.scss";
</style>

<script>
import RimconfigBreadcrumb from '@/components/RimconfigBreadcrumb';
import { mapState } from 'vuex';
import { breadcrumbItems, tableFields, tableFieldExpand, selectFilterFields, tableFieldsRetailPrice } from './configs.js';
import IconCaretDouble from '@/components/icons/IconCaretDouble';
import BaseAddToCartDialog from '@/components/BaseAddToCartDialog';
import { getImageBySize } from '@/utils/index.js';

export default {
  components: {
    RimconfigBreadcrumb,
    IconCaretDouble,
    BaseAddToCartDialog,
  },
  data() {
    return {
      expanded: [],
      singleExpand: true,
      tpmsGroupFilterCheckboxes: [],
      inputGroupFilter: {},
      inputSelectTpmsKeyFilter: [],
      inputSelectTpmsValueFilter: {
        group: [],
      },

      selectFilterOptions: {
        manufacturer: [],
      },
      inputSelectFilter: {},

      cartItems: [],
      isLoading: false,
      cartItemsBySupplier: {},
      selectQuantity: {},
    };
  },
  computed: {
    ...mapState({
      tpmsSearchData: (state) => state.rimconfigTpms.tpmsSearchData,
      tpmsSearchAssignByTpmsTag: (state) => state.rimconfigTpms.tpmsAssignByTpmsTag,
    }),
    ...mapState('pricing', ['priceType']),
    breadcrumbItems() {
      return breadcrumbItems;
    },
    tableFields() {
      if (this.priceType === 'retailPrice' || this.priceType === 'retailTaxPrice') {
        return tableFieldsRetailPrice;
      } else {
        return tableFields;
      }
    },
    tableFieldExpand() {
      return tableFieldExpand;
    },
    selectFilterFields() {
      return selectFilterFields;
    },
    filteredTpmsSearchData() {
      if (this.inputSelectTpmsKeyFilter.length === 0) {
        return this.tpmsSearchData;
      }
      return this.tpmsSearchData.slice().filter((item) => {
        let filterResult = true;
        for (let key of this.inputSelectTpmsKeyFilter) {
          filterResult = this.inputSelectTpmsValueFilter[key].includes(item[key]) && filterResult;
        }
        return filterResult;
      });
    },
    getType() {
      return this.$route.query.type;
    },
    getSearchValue() {
      return this.$route.query.value;
    },
    getHsnTsnVsn() {
      return this.$route.params.hsnTsnVsn;
    },
  },
  async created() {
    this.isLoading = true;
    try {
      if (this.getType === 'ean') {
        await this.$store.dispatch('rimconfigTpms/getSensorsByEan', this.getSearchValue);
      } else {
        await this.$store.dispatch('rimconfigTpms/getSensorsByMan', this.getSearchValue);
      }
      if (this.tpmsSearchData.length === 0) {
        return;
      }
      this.setTpmsGroupFilterCheckboxes();
      this.setTpmsGroupManufacturer();
      this.setInitInputSelectFilter();
      this.setInitSelectQuantity();

    } catch (err) {
      if (err.response.status === 500) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.tpms-not-found'),
          message: this.$t('langkey.please-select-a-new-car'),
        });
        this.$store.dispatch('rimconfigCarSelectorTpms/clearCarSelector')
          .then(() => {
            this.$router.push(this.$i18nRoute({
              name: 'rims-config-tpms',
            }));
          });
      }
    } finally {
      this.isLoading = false;
    }
  },
  beforeDestroy() {
    this.$store.dispatch('rimconfigTpms/clearTpmsCar');
  },
  methods: {
    onSelectCheckboxFilter(event, group) {
      if (event) {
        if (!this.inputSelectTpmsKeyFilter.includes('group')) {
          this.inputSelectTpmsKeyFilter.push('group');
        }
        this.inputSelectTpmsValueFilter.group.push(group);
      } else {
        const tmpCurrentSelectTpmsGroupFilter = this.inputSelectTpmsValueFilter.group;
        this.inputSelectTpmsValueFilter.group = tmpCurrentSelectTpmsGroupFilter.filter((item) => item !== group);
        if (this.inputSelectTpmsValueFilter.group.length === 0) {
          const tmpCurrentTpmsKeyFilter = this.inputSelectTpmsKeyFilter;
          this.inputSelectTpmsKeyFilter = tmpCurrentTpmsKeyFilter.filter((item) => item !== 'group');
        }
      }
    },
    onSelectDropdownFilter(event, key) {
      if (event && event.length) {
        if (!this.inputSelectTpmsKeyFilter.includes(key)) {
          this.inputSelectTpmsKeyFilter.push(key);
        }
        this.inputSelectTpmsValueFilter[key] = event;
      } else {
        this.inputSelectTpmsValueFilter[key] = [];
        if (this.inputSelectTpmsValueFilter[key].length === 0) {
          const tmpCurrentTpmsKeyFilter = this.inputSelectTpmsKeyFilter;
          this.inputSelectTpmsKeyFilter = tmpCurrentTpmsKeyFilter.filter((item) => item !== key);
        }
      }
    },
    setTpmsGroupFilterCheckboxes() {
      const getTpmsGroups = this.tpmsSearchData.map((item) => item.group);
      const uniqueTpmsGroups = [...new Set(getTpmsGroups)];
      this.tpmsGroupFilterCheckboxes = uniqueTpmsGroups;
      this.tpmsGroupFilterCheckboxes.forEach((item) => {
        this.inputGroupFilter = {
          ...this.inputGroupFilter,
          [item]: false,
        };
      });
    },
    expandRow(item) {
      if (item.children)
        this.expanded = (this.expanded[0] && this.expanded[0].articleId === item.articleId) ? [] : [item];
    },
    setInitSelectQuantity() {
      this.filteredTpmsSearchData.forEach((item) => {
        const itemKey = `${item.man}${item.ean}`;
        this.selectQuantity = {
          ...this.selectQuantity,
          [itemKey]: 4,
        };
      });
    },
    order(item) {
      this.cartItems = [];
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      this.$store.dispatch('rimconfigTpms/getTpmsAssigns', {
        tpmsTag: item.tpmsTag,
        assigns: item.assigns,
      })
        .then(() => {
          const tpmsAssigns = this.tpmsSearchAssignByTpmsTag[item.tpmsTag].slice()
            .map((assignItem) => {
              return {
                ...assignItem,
                listPrice: item.prices.bestPrice,
              };
            });
          this.cartItems.push({
            ...item,
            imageTemplate: item.imageTemplate,
            description: item.description1,
            articleId: item.man,
            quantity: this.selectQuantity[`${item.man}${item.ean}`] || 1,
            price: item.price || 0,
            suppliers: tpmsAssigns,
          });
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$refs.addToCartDialog.show();
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    onSupplierAddToCart({ item, quantity }) {
      this.$refs.addToCartDialog.hide();
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      this.$store.dispatch('cart/addCartItem', {
        id: item.id,
        quantity: quantity,
      })
        .then(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$router.push(this.$i18nRoute({
            name: 'cart',
          }));
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error Add item to cart',
            message: err,
          });
        });
    },
    getTpmsImageBySize(image) {
      if (image.includes('https://cdn.jfnet.de/tpms')) {
        return getImageBySize(image, 120, 120);
      }

      return getImageBySize(image, 120, 120).replace('https://cdn.jfnet.de/', 'https://cdn.jfnet.de/tpms/');
    },
    setInitInputSelectFilter() {
      selectFilterFields.forEach((item) => {
        this.inputSelectFilter = {
          ...this.inputSelectFilter,
          [item.key]: [],
        };
        this.inputSelectTpmsValueFilter = {
          ...this.inputSelectTpmsValueFilter,
          [item.key]: [],
        };
      });
    },
    setTpmsGroupManufacturer() {
      const getTpmsManufacturer = this.tpmsSearchData.map((item) => item.manufacturer);
      const uniqueTpmsManufacturer = [...new Set(getTpmsManufacturer)];
      this.selectFilterOptions.manufacturer = uniqueTpmsManufacturer.map((item) => {
        return { label: item, value: item };
      });
    },
    onResetFilter() {
      this.setInitInputSelectFilter();
      this.inputSelectTpmsKeyFilter = [];
      for (const key in this.inputGroupFilter) {
        this.inputGroupFilter[key] = false;
      }
    },
    onSubtractQuantity({ articleId, quantity }) {
      this.cartItemsBySupplier[articleId] = quantity;
      this.cartItems[0].suppliers[0].quantity = quantity;
    },
    onAddQuantity({ articleId, quantity }) {
      this.cartItemsBySupplier[articleId] = quantity;
      this.cartItems[0].suppliers[0].quantity = quantity;
    },
    onUpdateQuantity ({ articleId, quantity }) {
      this.cartItemsBySupplier[articleId] = quantity;
      this.cartItems[0].suppliers[0].quantity = quantity;
    },
    setCarSelectorStep(step) {
      const promise1 = this.$store.dispatch('rimconfigCarSelectorTpms/setStep', step);
      const promise2 = this.$store.dispatch('rimconfigCarSelectorTpms/setSearchByKbaNumber', false);
      Promise.all([promise1, promise2])
        .then(() => {
          this.$router.push(this.$i18nRoute({
            name: 'rims-config-tpms',
          }));
        });
    },
    onConfirmAndShowCart() {
      let promises = [];
      Object.keys(this.cartItemsBySupplier).forEach((key) => {
        promises.push(this.$store.dispatch('cart/addCartItem', {
          id: key,
          quantity: this.cartItemsBySupplier[key],
        }));
      });
      Promise.all(promises)
        .then(() => {
          this.$refs.addToCartDialog.hide();
          this.$router.push(this.$i18nRoute({
            name: 'cart',
          }));
        });
    },
    onContinueShopping() {
      let promises = [];
      Object.keys(this.cartItemsBySupplier).forEach((key) => {
        promises.push(this.$store.dispatch('cart/addCartItem', {
          id: key,
          quantity: this.cartItemsBySupplier[key],
        }));
      });
      Promise.all(promises)
        .then(() => {
          this.$refs.addToCartDialog.hide();
        });
    },
    displayBestPrice(bestPrice) {
      return parseFloat(bestPrice.toString()).toFixed(2).replace('.', ',');
    },
  },
};
</script>